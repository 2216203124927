.Header{
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
}

.Header .logo{
    width: 150px;
}

.Header nav ul{
    padding-left: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;
}

.Header nav ul li{
    margin-left: 15px;
    display: flex;
    padding: 0 20px;
    align-items: center !important;
    position: relative;
}

.fuelhub_navlink span{
    margin-right: 5px;
    color: var(--red);
}

.Header nav ul li a{
    color: white;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header nav ul li a i{
    margin-left: 20px;
}

.Header nav>ul>li:not(:nth-child(6))>a:after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background-color: var(--red);
    bottom: -5px;
    transition: width 0.3s;
}

.Header nav ul>li:hover a:after {
    width: 100%;
}

.activeNav::after{
    width: 100% !important;
}
/* .training_link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.training_link ul{
    background-color: var(--black);
    z-index: 99999999;
    transform: translateY(-100px);
    opacity: 0;
    transition: 0.5s all;

} */
/* Dropdown styling */
nav ul li ul {
    position: absolute;
    top: 100%;
    left: 0;
    display: none !important;
    background-color: var(--black);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeInDown 0.3s ease;
  }
  
  nav ul li:hover > ul {
    display: block !important;
    padding-left: 0;
    background-color: var(--black);
    z-index: 999999999999;
  }
  
  nav ul ul li {
    width:fit-content;
    padding: 10px;
    margin: 10px 0 !important;
  }
  nav ul ul li a{
    color: white;
    white-space: nowrap;
    transition: 0.3s all;
  }
  nav ul ul li a:hover{
    color: var(--red);
  }
  
  /* Hover animation */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
.mobile_menu{
    display: none;
}



@media screen and (max-width:500px) {

    nav ul ul li {
        width:fit-content;
        padding: 10px;
        margin: 0px 0 !important;
      }

    .Header{
        padding: 0 5%;
    }
    .Header .logo{
        width: 120px;
    }
    .mobile_menu{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--red);
        height: 40px;
        width: 40px;
        border-radius: 3px;
    }
    .Header nav{
        position: fixed;
        top: 100px;
        transform: translateX(100vw);
        width: 100%;
        height: 100%;
        background-color: var(--black);
        z-index: 9999;
        transition: 0.3s ease-in-out;
    }
    .showNav{
        transform: translateX(0) !important;
        transition: 0.3s ease-in-out !important;
    }
    .Header nav ul{
        flex-direction: column;
        text-align: center;
    }
    .Header nav ul li{
        margin: auto;
        width:fit-content;
    }
    .Header nav ul li a{
        width: fit-content;
        margin: auto;
        text-align: center;
        padding: 20px 0;
    }
    .mobile_menu i{
        font-size: 25px;
    }
}
