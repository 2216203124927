/* banner carousal */
.HomePage .carousel-indicators{
    list-style: none;
    bottom: -50px;
}

.HomePage .carousel-indicators .active {
    opacity: 1 !important;
    width: 40px !important;
    border-radius: 0px !important;

}
.HomePage .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 7px;
    margin-left: 7px;
    text-indent: -999px;
    cursor: pointer;
    border-radius: 100%;
    background-color: var(--red);
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

/* banner section */

.HomePage .banner {
    display: flex;
    margin-top: 100px;
}

.HomePage .banner .left_section h1 {
    font-size: 50px;
}

.HomePage .banner .right_section img {
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
}

.HomePage .banner .left_section {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.HomePage .banner .left_section {
    width: 60%;
    padding-right: 20px;
}

.HomePage .banner .right_section {
    width: 40%;
    padding-left: 20px;
}



/* challenge section */

.HomePage .challenge {
    margin-top: 100px;
}


.HomePage .challenge .gallery {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 5%;
}

.HomePage .challenge .gallery img {
    width: 29%;
}

.HomePage .challenge .gallery img:nth-child(4),
.HomePage .challenge .gallery img:nth-child(5) {
    width: 35%;
}


/* our trainings */

.HomePage .our_trainings {
    margin-top: 100px;
}

.HomePage .our_trainings ._badges {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    justify-content: space-between;
}

.HomePage .our_trainings ._badges ._badge {
    width: 30%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.HomePage .our_trainings ._badges ._badge img {
    width: 100%;
    opacity: 75%;
}

.HomePage .our_trainings ._badges ._badge::before {
    width: 0%;
    height: 2px;
    background-color: white;
    z-index: 99;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    transition: 0.5s all;
}

.HomePage .our_trainings ._badges ._badge:hover::before {
    width: 100%;
}

.HomePage .our_trainings ._badges ._badge .overlay_text {
    position: absolute;
    top: 0;
    padding: 20px;
    height: 100%;
    font-size: 13px;
    width: 100%;
    left: 0;
    transform: translateY(-100%);
    transition: 0.3s ease-in-out;
    background-color: black;
}

.HomePage .our_trainings ._badges ._badge:hover .overlay_text {
    transform: translateY(0%);
}

.HomePage .our_trainings ._badges ._badge button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 10px;
    background: none;
    color: white;
    border: solid white 2px;
    border-radius: 2px;
    min-width: 200px;
    white-space: nowrap;
    max-width: fit-content;
    padding: 8px;
}

.HomePage .our_trainings .viewgallery_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}


/* supplement banner */
.HomePage .supplement{
    margin-top: 100px;
}
.HomePage .supplement section{
    display: flex;
    padding: 0px 0;
    justify-content: space-between;
    align-items: center;
}
.HomePage .supplement .left_section{
    width: 65%;
}
.HomePage .supplement .left_section{
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    justify-content: space-between;
}
.HomePage .supplement .left_section>h1 span{
    color: var(--red);
    margin-left: 5px;
}

.HomePage .supplement .right_section{
width: 30%;
}

/* why choose us */

.HomePage .whychoose_us {
    margin-top: 100px;
}

.HomePage .whychoose_us section {
    display: flex;
}

.HomePage .whychoose_us section .left_section {
    width: 60%;
}

.HomePage .whychoose_us section .right_section {
    width: 40%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

/* our trainers */

.HomePage .our_trainers {
    margin-top: 100px;
}

.HomePage .our_trainers ._badges {
    display: flex;
    justify-content: space-between;
}

.HomePage .our_trainers ._badges ._badge {
    position: relative;
    width: 30%;
    height: fit-content;
}

.HomePage .our_trainers ._badges ._badge img {
    width: 100%;
    opacity: 80%;
}

.HomePage .our_trainers ._badges ._badge .content {

    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

/* counter */

.HomePage .counter {
    margin-top: 100px;
    width: 100%;
    height: fit-content;
    background-image: url('../Assets/counter\ bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px 40px;
    background-size: 110%;
    border: solid rgba(128, 128, 128, 0.541) 2px;
    position: relative;
}

.HomePage .counter .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.726);
}

.HomePage .counter ._badges {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 999;
}

.HomePage .counter ._badges ._badge {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.HomePage .counter ._badges ._badge>div{
    display: flex;
    align-items: center;
}
.HomePage .counter ._badges ._badge>div>p{
    font-size: 40px;
    font-weight: 600;
    margin-left: 10px;
}
.HomePage .counter ._badges ._badge h1 {
    font-size: 50px;
}

.HomePage .counter ._badges p {
    margin-bottom: 0;
}

/* Program Pricing */
.HomePage .pricing {
    margin-top: 100px;
}

.HomePage .pricing ._badges {
    display: flex;
    justify-content: space-between;
}

.HomePage .pricing ._badges ._badge {
    width: 24%;
    padding: 20px;
    border: solid var(--red) 2px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.HomePage .pricing ._badges ._badge p {
    font-size: 13px;
    line-height: 20px;
}

.HomePage .pricing ._badges ._badge ul {
    padding-left: 10px;
    text-align: left;
}

.HomePage .pricing ._badges ._badge ul li {
    line-height: 35px;
    position: relative;
    list-style-image: url('../Assets//line\ point.jpg');
}

.HomePage .pricing ._badges ._badge .subscribe_btn {
    width: 100%;
    color: white;
    border: solid var(--red) 2px;
    background: none;
    border-radius: 2px;
    padding: 10px;
    transition: 0.5s all;   
}

.HomePage .pricing ._badges ._badge .subscribe_btn:hover {
    background: var(--red);
}
.error{
    font-size: 12px;
    color: white;
    background-color: var(--red);
    padding: 0px 5px;
    border-radius: 3px;
    animation:blink 0.5s infinite alternate;
}
@keyframes blink {
    from{
        opacity: 0;
    }
    to{
        opacity:1;
    }
}
/* review */

.HomePage .review {
    margin-top: 100px;
}
.HomePage .review .reviewer_name{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.HomePage .review .carousel-item section {
    display: flex;
    justify-content: space-between;
}

.HomePage .review .carousel-item ._badge {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 20px;
    border-radius: 2px;
    border: solid var(--red) 2px;
}

.HomePage .review .carousel-item ._badge .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.HomePage .review .carousel-item ._badge .top img {
    border-radius: 100%;
    height: 50px;
    width: 50px;
}

.HomePage .review .carousel-item ._badge .top>div {
    display: flex;
    gap: 0 10px;
    align-items: center;
}

.HomePage .review .carousel-item ._badge .top .left span .reviewer_location {
    font-size: 12px;
    opacity: 80%;
}

.HomePage .review .carousel-item ._badge .top .right h3 {
    font-size: 15px;
}

.HomePage .review .carousel-item ._badge .top .right i {
    color: #CE9A15;
}

.HomePage .review .carousel-item h5,
.HomePage .review .carousel-item h3,
.HomePage .review .carousel-item p {
    margin-bottom: 0;
}

.HomePage .review .carousel-item ._badge .content {
    font-size: 14px;
    letter-spacing: 0 !important;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.HomePage .review .navigation_btn {
    width: fit-content;
    margin-left: auto;
    display: flex;
    gap: 0 20px;
    margin-top: 20px;
}

.HomePage .review .navigation_btn button {
    position: unset !important;
    background-color: var(--red);
    height: 35px;
    border-radius: 100%;
    width: 35px;
}

.HomePage .review .carousel-control-next-icon,
.carousel-control-prev-icon {
    display: none !important;
}

.HomePage .review .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: unset !important;
    padding: 0;
    margin-right: 0%;
    margin-bottom: 0;
    margin-left: 0%;
    width: fit-content !important;
}

.HomePage .review .carousel-indicators .active {
    width: 50px !important;
    border-radius: 7px !important;
    background-color: var(--red) !important;
    opacity: 1 !important;
}

.HomePage .review .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color: var(--red);
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

/* contact */

.HomePage .contact {
    margin-top: 100px;
    width: 80%;
    margin: auto;
}
.HomePage .contact .heading{
    margin-bottom: 20px !important;
    margin-top: 80px;
}

.HomePage .contact>p {
    width: 80%;
    text-align: center;
    margin: auto;
}

.HomePage .contact  section{
    display: flex;
    margin-top: 50px;
}
.HomePage .contact  section .left_section,.HomePage .contact  section .right_section{
    width: 50%;
}

.HomePage .contact  section .right_section{
    padding-left: 50px;
}
.HomePage .contact  section .right_section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.HomePage .contact  section .right_section>div{
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.HomePage .contact  section .right_section>div input,.HomePage .contact  section .right_section>div textarea{
    width: 100%;
    background: none;
    border: solid var(--red) 2px;
    border-radius: 5px;
    padding: 7px;
}

.HomePage .contact  section .right_section>div input::placeholder,.HomePage .contact  section .right_section>div textarea::placeholder{
    font-size: 14px
}

.HomePage .contact  section .right_section>div input:focus,.HomePage .contact  section .right_section>div textarea:focus{
    outline: none;
}

.HomePage .contact  section .right_section>div textarea{
    min-height: 250px;
}

.HomePage .contact  section .right_section button{
    margin-left: auto;
}

/* scroll to top */
.scroll_to_top{
    background-color: var(--red);
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    text-decoration: none;
    height: 50px;
    width: 50px;
    cursor: pointer;
    transition: 0.3s all;
}
.scroll_to_top:hover{
    background-color: var(--darkred);
}
.scroll_to_top i{
    color: white;
    font-size: 22px;
}

/* calculator */
.HomePage .calculator .heading{
    margin-bottom: 20px !important
}
.HomePage .calculator>p{
    text-align: center;
}
.HomePage .calculator input,select{
    border: solid var(--red) 2px;
    border-radius: 5px;
    padding: 7px;
    background-color: transparent;
    color: white;
    width: 100%;
}
.HomePage .calculator select option{
    color: var(--black);
}

.HomePage .calculator .calculatorcomp{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.HomePage .calculator .calculatorcomp>div{
    width: 48%;
}
.HomePage .calculator .calculatorcomp .calciform{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.HomePage .calculator .calculatorcomp .calciform div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.HomePage .calculator .calculatorcomp .calciform>div label{
    min-width: 150px;
}
.HomePage .calculator .calculatorcomp .calciform button{
    min-width: 100%;
}
.HomePage .calculator .calculatorcomp .result p span{
    color: var(--red);
}
.HomePage .calculator .calculatorcomp .result table{
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    table-layout: fixed;
}
.HomePage .calculator .calculatorcomp .result table td{
    padding: 10px;
    border: solid var(--red) 2px;
}
.HomePage .calculator .note{
    color: var(--red);
    font-size: 13px;
    width: 48%;
    text-align: left;
    margin-top: 20px;
}

.HomePage .calculator  table span{
    font-size: 10px !important;
    opacity: 50%;
}




/* download app */
.HomePage .download_app{
    display: flex;
    background: url('../Assets/DBANNER.jpg');
    align-items: center;
background-position: right;
justify-content: center;
height: fit-content;
max-height: fit-content;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 100px;
    height: 450PX;
    position: relative;
}
.HomePage .download_app .download_btn{
    position: absolute;
    bottom: 50px;
    right: 5%;
}
.HomePage .download_app .download_btn{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.HomePage .download_app .download_btn button{
    border-radius: 20px;
}
.HomePage .download_app .download_btn img{
    width: 180px;
}
.HomePage .download_app>div{
    width: 35%;
}
.HomePage .download_app .left_section{
    display: flex;
    align-items: center;
    justify-content: center;
}
.HomePage .download_app .left_section img{
   width: 90%;
   object-fit: cover;
}
.HomePage .download_app .left_section div{
    width: 100%;
}
.HomePage .download_app .right_section section{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 20px;
    width: fit-content;
    background-color: rgba(255, 255, 255, 0.178);
}
.HomePage .download_app .right_section section div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.HomePage .download_app .right_section section div img{
    width: 150px;
}

.HomePage .franchise_banner{
    background-image: url('../Assets/NEWFRANCHISE.jpg');
    width: 100%;
    height: 100%;
    position: relative;
    background-size:cover;
    margin-top: 50px;
}
.HomePage .franchise_banner button{
    position: absolute;
    left: 28%;
    bottom: 100px;
}
/* franchise banner */

/* mobile view */
@media screen and (max-width:500px) {
    /* banner section */

.HomePage .banner {
    flex-direction: column;
    margin-top: 50px;
    gap: 20px 0;
}
.HomePage .banner .left_section h1 {
    font-size: 40px;
}

.HomePage .banner .left_section {
    width: 100%;
    padding-right: 20px;
}
.HomePage .banner .left_section>button{
    margin: auto;
}

.HomePage .banner .right_section {
    width: 100%;
    padding-left: 00px;
}

/* challenge section */

.HomePage .challenge {
    margin-top: 50px;
}

.HomePage .challenge .gallery {
    gap: 20px 2%;
}

.HomePage .challenge .gallery img {
    width: 48%;
}

.HomePage .challenge .gallery img:nth-child(4){
    width: 48%;
}
.HomePage .challenge .gallery img:nth-child(5) {
    width: 100%;
}

/* our trainings */

.HomePage .our_trainings {
    margin-top: 50px;
}



.HomePage .our_trainings ._badges ._badge {
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.HomePage .our_trainings ._badges ._badge img {
    width: 100%;
}

.HomePage .our_trainings ._badges ._badge::before {
    width: 0%;
    height: 2px;
    background-color: white;
    z-index: 99;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    transition: 0.5s all;
}

.HomePage .our_trainings ._badges ._badge:hover::before {
    width: 100%;
}

.HomePage .our_trainings ._badges ._badge .overlay_text {
    padding: 10px;
    font-size: 10px;
}

.HomePage .our_trainings ._badges ._badge button {
    bottom: 10px;
}

/* supplement banner */
.HomePage .supplement{
    margin-top: 50px;
}
.HomePage .supplement section{
    display: flex;
    flex-direction: column;
    gap: 30px 0;
}
.HomePage .supplement .left_section{
    width: 100%;
}
.HomePage .supplement .left_section>button{
    margin: auto;
}
.HomePage .supplement .right_section{
width: 100%;
}


/* why choose us */

.HomePage .whychoose_us {
    margin-top: 50px;
}

.HomePage .whychoose_us section {
    flex-direction: column;
    gap: 20px 0;
}

.HomePage .whychoose_us section .left_section {
    width: 100%;
}

.HomePage .whychoose_us section .right_section {
    width: 100%;
    margin-left: 00px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

/* our trainers */

.HomePage .our_trainers {
    margin-top: 50px;
}

.HomePage .our_trainers ._badges {
    flex-direction: column;
    gap: 20px 0;
}

.HomePage .our_trainers ._badges ._badge {
    position: relative;
    width: 100%;
    height: fit-content;
}

/* counter */

.HomePage .counter {
    margin-top: 50px;
    width: 100%;
    height: fit-content;
    background-image: url('../Assets/counter\ bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px;
    position: relative;
}

.HomePage .counter .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.726);
}

.HomePage .counter ._badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    gap: 20px 0;
}

.HomePage .counter ._badges ._badge {
    text-align: center;
    width: 50%;
}

.HomePage .counter ._badges ._badge h1 {
    font-size: 40px;
}

.HomePage .counter ._badges p {
    margin-bottom: 0;
}


/* Program Pricing */
.HomePage .pricing {
    margin-top: 50px;
}

.HomePage .pricing ._badges {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.HomePage .pricing ._badges ._badge {
    width: 100%;
}

/* review */

.HomePage .review {
    margin-top: 50px;
}
.HomePage .review .heading{
    width: 80%;
}
.HomePage .review .carousel-item section {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    justify-content: space-between;
}

.HomePage .review .carousel-item ._badge {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    border: solid var(--red) 2px;
}

/* contact */

.HomePage .contact {
    margin-top: 50px;
    width: 100%;
}
.HomePage .contact .heading{
    margin-bottom: 20px !important;
    margin-top: 80px;
}

.HomePage .contact>p {
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.HomePage .contact  section{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.HomePage .contact  section .left_section,.HomePage .contact  section .right_section{
    width: 100%;
}

.HomePage .contact  section .right_section{
    padding-left: 00px;
}
.HomePage .contact  section .right_section{
    display: flex;
    gap: 20px 0;
    flex-direction: column;
}

.HomePage .contact  section .right_section button{
    margin: auto;
}


.HomePage .download_app{
    display: flex;
    background: url('../Assets/dmbanner.jpg');
    align-items: unset;
background-position: center;
justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 00px;
    height: 90vh !important;
    max-height: 90vh !important;
    width: 100%;
    position: relative;
}

.HomePage .download_app .download_btn{
    position: absolute;
    bottom: 50px;
    right: 40%;
}

.HomePage .download_app .download_btn button{
    background: transparent;
}
.HomePage .download_app .download_btn button:focus{
    background: transparent;
}
.HomePage .franchise_banner button {
    position: absolute;
    left: 28%;
    top: 221px;
    height: fit-content;
}

/* calculator */

.HomePage .calculator .calculatorcomp{
    flex-direction: column;
    gap: 20px;
}
.HomePage .calculator .calculatorcomp>div{
    width: 100%;
}
.HomePage .calculator .note{
    width: 100%;
}














}