
/* banner */

.OnlineTraining .banner{
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/obanner.jpg');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 0px -180px;
}

.OnlineTraining .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5); /* Adjust the color and opacity as needed */
  }
  
  .OnlineTraining .banner h1 {
    position: relative;
    z-index: 1; /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff; /* Set the color of your text */
  }

  .OnlineTraining .banner_content{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .OnlineTraining .banner_content span{
    color: var(--red);
  }
/* Why Choose Our Online Training? */
.OnlineTraining .why_choose ._badges{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.OnlineTraining .why_choose ._badges ._badge{
    width: 30%;
    padding: 20px;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: solid var(--red) 2px;
    border-radius: 3px;
}

/* How It Works ? */
.OnlineTraining .how_works section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.OnlineTraining .how_works section .left_section{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 50px 0;
}
.OnlineTraining .how_works section .left_section div p span{
    color: var(--red);
    width: fit-content;
    font-size: 25px;
}
.OnlineTraining .how_works section .right_section{
    width: 40%;
}
.OnlineTraining .how_works section .right_section div{
    margin-left:30px;
}

/* Our Sessions */

.OnlineTraining .our_sessions ._badges{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px 0;
}

.OnlineTraining .our_sessions ._badges ._badge{
    width: 30%;
}

/* Get Started Today! */
.OnlineTraining .get_started .heading{
    margin-bottom: 30px;
}
.OnlineTraining .get_started>p{
    width: 80%;
    text-align: center;
    margin: auto;
}
.OnlineTraining .get_started>p span{
    color: var(--red);
}
.OnlineTraining .get_started ._badges {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.OnlineTraining .get_started ._badges ._badge {
    width: 24%;
    padding: 20px;
    border: solid var(--red) 2px;
    border-radius: 2px;
    text-align: center;
}

.OnlineTraining .get_started ._badges ._badge p {
    font-size: 13px;
    line-height: 20px;
}

.OnlineTraining .get_started ._badges ._badge ul {
    padding-left: 10px;
    text-align: left;
}

.OnlineTraining .get_started ._badges ._badge ul li {
    line-height: 35px;
    font-size: 13px;
    position: relative;
    list-style-image: url('../Assets/line\ point.jpg'); /* Corrected URL */

}

.OnlineTraining .get_started ._badges ._badge .subscribe_btn {
    width: 100%;
    color: white;
    border: solid var(--red) 2px;
    background: none;
    border-radius: 2px;
    padding: 10px;
    transition: 0.5s all;
}

.OnlineTraining .get_started ._badges ._badge .subscribe_btn:hover {
    background: var(--red);
}

/* train with app */
/* twdn */
.OnlineTraining .twdn .heading,.OnlineTraining .twfx .heading{
    margin-bottom: 30px;
}
.OnlineTraining .twdn>p,.OnlineTraining .twfx>p{
    width: 80%;
    text-align: center;
    margin: auto;
}
.OnlineTraining .twdn section,.OnlineTraining .twfx section{
    display: flex;
    margin-top: 50px;
    justify-content: space-between
}

.OnlineTraining .twdn section .left{
    width: 40%;
}
.OnlineTraining .twdn section .right{
    width: 60%;
    margin-left: 20px;
}
.OnlineTraining .twdn section .left{
    display: flex;
    justify-content: space-between;
}
.OnlineTraining .twdn section .left>div{
    width:100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid var(--red) 2px;
    border-radius: 2px;
    text-align: center;
}
.OnlineTraining .twfx section .left>div{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid var(--red) 2px;
    border-radius: 2px;
    text-align: center;
}
.OnlineTraining .twdn section .left>div .light_tex,.OnlineTraining .twfx section .left>div .light_text{
    font-size: 13px;
}
.OnlineTraining .twdn section .left>div .enquire_btn,.OnlineTraining .twfx section .left>div .enquire_btn{
    width: 100%;
    color: white;
    border: solid var(--red) 2px;
    background: none;
    border-radius: 2px;
    padding: 10px;
    transition: 0.5s all;  
}
.OnlineTraining .twdn section .left>div .enquire_btn:hover ,.OnlineTraining .twfx section .left>div .enquire_btn:hover {
    background: var(--red);
}
.OnlineTraining .twdn section .left>div .note,.OnlineTraining .twfx section .left>div .note{
    text-align: left;
    font-size: 12px;
    opacity: 50%;
}
.OnlineTraining .twdn section .right{
    background: url(../Assets/appimage.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -150px;
    height: 500px;
}

.OnlineTraining .twdn section ul,.OnlineTraining .twfx section ul {
    padding-left: 10px;
    text-align: left;
}

.OnlineTraining .twdn section ul li,.OnlineTraining .twfx section ul li {
    line-height: 35px;
   
    position: relative;
    list-style-image: url('../Assets/line\ point.jpg'); /* Corrected URL */

}


/* mobile view */

@media screen and (max-width:500px) {
    .OnlineTraining .banner{
        background-position: -100px 0px;
    }
    .OnlineTraining .banner_content {
        width: 100%;
    }
    .OnlineTraining .why_choose ._badges{
        gap: 20px 0;
    }
    .OnlineTraining .why_choose ._badges ._badge {
        width:100%;
    }
    .OnlineTraining .how_works section {
      flex-direction: column;
      gap: 20px 0;
    }
    .OnlineTraining .how_works section .left_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px 0;
    }
    .OnlineTraining .how_works section .right_section {
        width: 100%;
    }
    .OnlineTraining .how_works section .right_section>div {
        margin-left: 0;
    }
    .OnlineTraining .our_sessions ._badges ._badge {
        width: 100%;
    }
    .OnlineTraining .get_started>p {
        width:100%;
    }
    .OnlineTraining .get_started ._badges {
       flex-wrap: wrap;
       gap: 20px;
        margin-top: 30px;
    }
    .OnlineTraining .get_started ._badges ._badge{
        width: 100%;
    }
  
    .OnlineTraining .twdn>p, .OnlineTraining .twfx>p {
        width: 100%;
    }
    .OnlineTraining .twdn section, .OnlineTraining .twfx section {
      flex-direction: column;
      gap: 20px 0;
    }
    .OnlineTraining .twdn section .left {
        width: 100%;
    }
    .OnlineTraining .twdn section .right {
       margin-left: 0;
       width: 100%;
       background-position: 0px 0px;
    }
}