.Enquiry{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.Enquiry .dummy{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.705);
   
}

.Enquiry form{
    background:var(--black);
    border-radius: 10px;
    position: relative  !important;
    z-index: 555555555500000000000000055 !important;
    width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
justify-content: center;
align-items: center;
padding: 20px;
}

.Enquiry form div{
    width: 100%;
    display: flex;
    align-items: baseline;
}

.Enquiry form div label{
    width: 150px;
}

.Enquiry form div input,.Enquiry form div textarea{
    width: calc(100% - 150px);
    padding: 0 5px;
    color: var(--black);
}
.Enquiry form ::placeholder{
    font-size: 16px;
}
.Enquiry form div textarea{
    min-height: 150px;
}

@media screen and (max-width:500px) {
    form{
        margin: 0 15px;
    }
    .Enquiry form div label {
        width: 85px;
    }
    .Enquiry form div input, .Enquiry form div textarea {
        width: calc(100% - 85px);
    }
}
