/* banner */

.Contact .banner {
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/contact_banner.webp');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contact .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    /* Adjust the color and opacity as needed */
}

.Contact .banner h1 {
    position: relative;
    z-index: 1;
    /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff;
    /* Set the color of your text */
}

.Contact .banner_content {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
}

.Contact .banner_content span {
    color: var(--red);
}

/* contact_badges */

.Contact .contact_badges {
    display: flex;

    justify-content: space-between;
}

.Contact .contact_badges ._badge {
    width: 30%;
    padding: 20px;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: solid var(--red) 2px;
    border-radius: 3px;
}

/* enquiry us */


.enq_us section {
    display: flex;
    margin-top: 50px;
}

.enq_us section .left_section {
    width: 40%;
}

.enq_us section .right_section {
    width: 60%;
}

.enq_us section .right_section {
    padding-left: 50px;
}

.enq_us section .left_section,
.franchise section .right_section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.enq_us section .left_section>div,
.franchise section .right_section>div {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.enq_us section .left_section>div input,
.enq_us section .left_section>div textarea,
.franchise section .right_section>div input,
.franchise section .right_section>div textarea {
    width: 100%;
    background: none;
    border: solid var(--red) 2px;
    border-radius: 5px;
    padding: 7px;
}

.enq_us section .left_section>div input::placeholder,
.enq_us section .left_section>div textarea::placeholder,
.franchise section .right_section>div input::placeholder,
.franchise section .right_section>div textarea::placeholder {
    font-size: 14px
}

.enq_us section .left_section>div input:focus,
.enq_us .contact section .left_section>div textarea:focus,
.franchise section .right_section>div input:focus,
.franchise section .right_section>div textarea:focus {
    outline: none;
}

.enq_us section .left_section>div textarea,
.franchise section .right_section>div textarea {
    min-height: 250px;
}

.enq_us section .left_section button,
.franchise section .right_section button {
    width: 100% !important;
    max-width: 100% !important;
}

/* franchise */
.Contact .franchise .mainpara {
    text-align: center;
}

.franchise section {
    display: flex;
    margin-top: 50px;
}

.franchise section .left_section {
    width: 60%;
}

.franchise section .right_section {
    width: 40%;
    padding-left: 50px;
}

.franchise .accordion-button {
    background-color: var(--red);
    color: white;
}

.franchise .accordion-body {
    background-color: var(--black);
    color: white;
}

.franchise .accordion-item {
    margin: 20px 0;
}

.franchise .accordion-button::after {
    filter: brightness(500);
}

.franchise .accordion-item {
    border: none;
}

/* mobile view */
@media screen and (max-width:500px) {

    .Contact .banner_content {
        width: 100%;
    }

    .Contact .contact_badges {
        flex-wrap: wrap;
        gap: 20px 0;
    }

    .Contact .contact_badges ._badge {
        width: 100%;
    }

    .enq_us section {
        flex-direction: column;
        margin-top: 30px;
        gap: 20px 0;
    }

    .enq_us section .left_section,
    .enq_us section .right_section {
        width: 100%;
        padding-left: 0;
    }

    .enq_us section .right_section {
        height: 400px;
    }

    .franchise section {
        flex-direction: column;
        gap: 20px;
    }

    .franchise section .left_section,.franchise section .right_section {
        width: 100%;
        padding-left: 0;
    }
    .franchise section .left_section>h4,.franchise section .right_section>h4{
        text-align: center;
    }







}