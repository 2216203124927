
/* banner */

.OfflineTraining .banner{
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/offline\ training\ banner.webp');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OfflineTraining .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5); /* Adjust the color and opacity as needed */
  }
  
  .OfflineTraining .banner h1 {
    position: relative;
    z-index: 1; /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff; /* Set the color of your text */
  }

  .OfflineTraining .banner_content{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .OfflineTraining .banner_content span{
    color: var(--red);
  }

  /* benefits */
  .OfflineTraining .benefits ._badges{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.OfflineTraining .benefits ._badges ._badge{
    width: 30%;
    padding: 20px;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: solid var(--red) 2px;
    border-radius: 3px;
}


/* How It Works ? */
.OfflineTraining .how_works section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.OfflineTraining .how_works section .left_section{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 50px 0;
}
.OfflineTraining .how_works section .left_section div p span{
    color: var(--red);
    width: fit-content;
    font-size: 25px;
}
.OfflineTraining .how_works section .right_section{
    width: 40%;
}
.OfflineTraining .how_works section .right_section div{
    margin-left:30px;
}

/* twdn */
.OfflineTraining .twdn .heading,.OfflineTraining .twfx .heading{
    margin-bottom: 30px;
}
.OfflineTraining .twdn>p,.OfflineTraining .twfx>p{
    width: 80%;
    text-align: center;
    margin: auto;
}
.OfflineTraining .twdn section,.OfflineTraining .twfx section{
    display: flex;
    margin-top: 50px;
    justify-content: space-between
}

.OfflineTraining .twdn section .left{
    width: 60%;
}
.OfflineTraining .twdn section .right{
    width: 40%;
    margin-left: 20px;
}
.OfflineTraining .twdn section .left{
    display: flex;
    justify-content: space-between;
}
.OfflineTraining .twdn section .left>div{
    width: calc(50% - 20px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid var(--red) 2px;
    border-radius: 2px;
    text-align: center;
}
.OfflineTraining .twfx section .left>div{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid var(--red) 2px;
    border-radius: 2px;
    text-align: center;
}
.OfflineTraining .twdn section .left>div .light_tex,.OfflineTraining .twfx section .left>div .light_text{
    font-size: 13px;
}
.OfflineTraining .twdn section .left>div .enquire_btn,.OfflineTraining .twfx section .left>div .enquire_btn{
    width: 100%;
    color: white;
    border: solid var(--red) 2px;
    background: none;
    border-radius: 2px;
    padding: 10px;
    transition: 0.5s all;  
}
.OfflineTraining .twdn section .left>div .enquire_btn:hover ,.OfflineTraining .twfx section .left>div .enquire_btn:hover {
    background: var(--red);
}
.OfflineTraining .twdn section .left>div .note,.OfflineTraining .twfx section .left>div .note{
    text-align: left;
    font-size: 12px;
    opacity: 50%;
}
/* .OfflineTraining .twdn section .right{
    background: url(../Assets/Contact\ image.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
} */

.OfflineTraining .twdn section .right{
   display: flex;
   justify-content: center;
   align-items: center;
}

.OfflineTraining .twdn section ul,.OfflineTraining .twfx section ul {
    padding-left: 10px;
    text-align: left;
}

.OfflineTraining .twdn section ul li,.OfflineTraining .twfx section ul li {
    line-height: 35px;
   
    position: relative;
    list-style-image: url('../Assets/line\ point.jpg'); /* Corrected URL */

}



.OfflineTraining .twfx section .left{
    width: 30%;
}
.OfflineTraining .twfx section .right{
    width: 70%;
    margin-left: 20px;
    display: flex;
  justify-content: space-between;
}
.OfflineTraining .twfx section .right div{
    width: 33%;
}

@media screen and (max-width:500px) {
    .OfflineTraining .banner_content {
        width: 100%;
    }
    .OfflineTraining .benefits ._badges {
        gap: 20px 0;
    }
    .OfflineTraining .benefits ._badges ._badge {
        width: 100%;
    }
    .OfflineTraining .how_works section{
        flex-direction: column;
        gap: 20px 0;
    }
    .OfflineTraining .how_works section .left_section, .OfflineTraining .how_works section .right_section {
        width: 100%;
    }
    .OfflineTraining .how_works section .left_section{
        gap: 20px 0;
    }
    .OfflineTraining .how_works section .right_section div {
        margin-left: 0px;
    }
    .OfflineTraining .twdn>p {
        width: 100%;
    }
    .OfflineTraining .twdn ._badges {
        margin-top: 30px;
        gap: 20px 0;
        flex-wrap: wrap;
    }
    .OfflineTraining .twdn ._badges ._badge{
        width: 100%;
    }
    .OfflineTraining .banner{
        background-position: -339px 0;
    }
    .OfflineTraining .twdn section,.OfflineTraining .twfx section{
        display: flex;
        flex-direction: column  ;
        gap: 20px 0;
    }
    .OfflineTraining .twdn section .right{
        width:100%;
    }
    .OfflineTraining .twdn section .left, .OfflineTraining .twfx section .left{
      width: 100% !important;
      flex-direction: column;
      gap: 20px 0;
    }
    .OfflineTraining .twdn section .left>div, .OfflineTraining .twfx section .left>div{
        
        width: 100%;
    }
    .OfflineTraining .twdn section .right{
        height: 350px;
        margin-left: 0;
    }
    .OfflineTraining .twdn>p, .OfflineTraining .twfx>p {
        width: 100%;
    }
    .OfflineTraining .twfx section .right{
        margin-left: 0;
        gap: 0px 20px;
        width: 100%;
    }
    .OfflineTraining .twfx section .right>div{
        width: 100%;
    }
    
}

@media screen and (min-width:500px) {
    .OfflineTraining .twdn section .right iframe{
        height: 450px;
    }
}