.Preloader{
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    z-index: 999999999;
    top: 0;
    left: 0;
}

.Preloader div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px 0;
}


.Preloader div .top rect,.Preloader div .bottom rect {
    animation: dash 1.5s ease-in  ;
}

.Preloader div .top path,.Preloader div .bottom path{
opacity: 0;
animation: show 1s ease-in forwards;
animation-delay: 1.2s;
}

@keyframes dash {
    0% {
        height: 0;
        width: 0;
    }
    
   
}
@keyframes show {
    to{
        opacity: 1;
    }
}