/* banner */

.Gallery .banner{
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/gym4.jpg');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Gallery .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5); /* Adjust the color and opacity as needed */
  }
  
  .Gallery .banner h1 {
    position: relative;
    z-index: 1; /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff; /* Set the color of your text */
  }

  .Gallery .banner_content{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .Gallery .banner_content span{
    color: var(--red);
  }