.Footer main{
    display: flex;
    justify-content: space-between;
    padding: 100px 0 50px  0;
}

.Footer main .footer_section{
    width: fit-content;
    max-width: 35%;
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.Footer main .footer_section .logo{
    width: 100px;
}

.Footer main .footer_section  .social_links ul{
    list-style: none;
    display: flex;
    padding-left: 0;
}

.Footer main .footer_section  .social_links ul li{
    margin-right: 20px;
}
.Footer main .footer_section  .social_links ul li a i{
    transition: 0.3s all;
}
.Footer main .footer_section  .social_links ul li a:hover i{
    color: var(--red)
}
.Footer main .footer_section ul{
    list-style: none;
    padding-left: 0;
}
.Footer main .footer_section ul li a{
    color: white;
    text-decoration: none;
    transition: 0.3s all;
}
.Footer main .footer_section ul li a:hover{
    color: var(--red)
}
.Footer main .footer_section ul li{
    line-height: 30px;
}

.Footer main .section3 ul li{
    display: flex;
    align-items: center;
}
.Footer main .section3 ul li div{
    min-width: 200px !important;
}
.Footer .copyrights{
    width: 60%;
    margin: auto;
    text-align: center;
    position: relative;
    padding: 10px 0;
    font-size: 12px
}
.Footer .copyrights::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: gray;
}


/* mobile view */

@media screen and (max-width:500px) {
    .Footer main{
        flex-direction: column;
        gap: 20px;
    }
    .Footer main .footer_section{
        width:100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    }
    .Footer .copyrights{
        width: 100%;
    }
    .Footer .copyrights::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: gray;
    }
}