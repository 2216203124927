/* banner */

.Supplement .banner{
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/about\ banner.webp');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Supplement .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5); /* Adjust the color and opacity as needed */
  }
  
  .Supplement .banner h1 {
    position: relative;
    z-index: 1; /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff; /* Set the color of your text */
  }

  .Supplement .banner_content{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .Supplement .banner_content span{
    color: var(--red);
  }


  .Supplement .brands {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    gap:20px 40px;
}

.Supplement .brands ._badge {
    width: calc(23% - 10px); /* Adjust the width as needed while accounting for gap */
    margin-bottom: 20px; /* Optional margin for spacing between rows */
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: center;
}

.Supplement .brands ._badge img {
    width: 50%;
    height: auto; /* Maintain image aspect ratio */
}


.Supplement .products{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 20px;
}

.Supplement .products ._badge{
    width: 22%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    background-color: #d6d6d627;
}

.Supplement .products ._badge img{
    width: 70%;
}
.Supplement .products ._badge h3{
    font-size: 13px;
}

.Supplement .bar{
    margin-top: 50px;
}

.bar select,.bar select option{
    color: var(--black) !important;
}

@media screen and (max-width:500px) {
    .bar select{
        width: 100%;
    }
    .Supplement .banner{
        background-position: 9000px -1px;
    }
    .Supplement .banner_content {
        width: 100%;
    }

    .Supplement .products ._badge ,.Supplement .brands{
        gap: 20px;
    }
    
    .Supplement .products ._badge ,.Supplement .brands ._badge{
        width: calc(47% - 5px);
    }
}