/* banner */

.About .banner{
    width: 100%;
    margin-top: 50px;
    background: url('../Assets/about\ banner.webp');
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.About .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5); /* Adjust the color and opacity as needed */
  }
  
  .About .banner h1 {
    position: relative;
    z-index: 1; /* Ensure the text is on top of the semi-transparent overlay */
    color: #ffffff; /* Set the color of your text */
  }

  .About .banner_content{
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
  }
  .About .banner_content span{
    color: var(--red);
  }

  /* our story */
  .About .our_story section{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .About .our_story section .left_section{
    width: 60%;
  }
  .About .our_story section .left_section p span{
    color: var(--red);
  }
  .About .our_story section .right_section{
    width: 38%;
  }

  /* unleash */
  .unleash>p{
    text-align: center;
  }

  .unleash ._badges{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    margin-top: 50px;
    justify-content: space-between;
  }

  
  .unleash ._badges ._badge{
    width: 23%;
    position: relative;
  }

  .unleash ._badges ._badge::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s all;
  }
  .unleash ._badges ._badge:hover::before{
    opacity: 0;
  }

  .unleash ._badges ._badge img{
    width: 100%;
  }

  /* What Sets Us Apart ?  */
  .what_set section{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
  }
  .what_set section p span{
    color: var(--red);
    font-size: 20px;
  }

  /* Your Transformation Awaits */

  .transformation>p{
    text-align: center;
  }
  .transformation>p span{
    color: var(--red);
  }
  .transformation ._badges{
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }
  .transformation ._badges ._badge{
    width: 30%;
  }
  .transformation ._badges ._badge img{
    width: 100%;
  }
  .transformation .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -50px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left:0;
}

.transformation .carousel-indicators .active{
    width: 50px !important;
    border-radius: 7px !important;
    background-color: var(--red) !important;
    opacity: 1 !important;
}

.transformation .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color:var(--red);
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

/* join */
.join p{
    text-align: center;
}
.join p span{
    color: var(--red);
}


/* mobile view */

@media screen and (max-width:500px) {
  .About .banner_content {
    width: 100%;
}
.About .our_story section {
 flex-direction: column;
}
.About .our_story section .left_section,.About .our_story section .right_section {
  width:100%;
}
.unleash ._badges {
 margin-top: 30px;
}
.unleash ._badges ._badge {
  width: 100%;
}
.join{
  margin-top: 100px;
}
.transformation ._badges {
  flex-wrap: wrap;
  gap: 20px;
}
.transformation ._badges ._badge {
  width: 100%;
}
.About .banner {
  background-position:-510px 0px; /* Adjust the value as needed */
}








}