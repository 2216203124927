@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'DM Serif Display', serif;
  letter-spacing: 1px;
  color: rgb(240, 240, 240);
  line-height:25px;
}

html,
body {
  background-color: var(--black);
  overflow-x: hidden;
}

:root {
  --black: #181818;
  --red: #eb1c24;
  --darkred: #be141d;

}
.flip img{
  transform: rotateY(180deg);
}

.heading{
  text-align: center;
  margin: auto;
  width: fit-content;
  margin-bottom: 50px;
  position: relative;
}

.heading::after{
  position: absolute;
  content: '';
  height: 3px;
  width: 50px;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: var(--red);
}


.heading span{
  color: var(--red);
}

.red_text {
  color: var(--red) !important;

}

.light_text{
  opacity: 80%;
}

main {
  width: 80%;
  margin: auto;
}

.red_btn {
  background-color: var(--red);
  color: white;
  padding: 7px 35px;
  border: none;
  border-radius: 3px;
  max-width: fit-content;
  transition: 0.5s all;
}

.red_btn:hover {
  background-color: var(--darkred);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sec{
  margin-top: 100px;
}

.popup{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.popup .dummy{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.popup .alert{
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid white 2px;
  background-color: white;
  color: black !important;
}
.popup .alert p{
  color: black !important;

}


@media screen and (max-width:500px) {
  
main {
  width: 90%;
  margin: auto;
}
.sec{
  margin-top: 50px;
}
.banner h1{
  text-align: center;
}
}